import { graphql } from "gatsby";
import React from "react";

import BlogPage from "../components/blog-page";

const BlogIndex = ({
  data: { avatar, backdrop, blogPosts, logo, site },
  location
}) => (
  <BlogPage
    avatar={avatar}
    backdrop={backdrop}
    location={location}
    logo={logo}
    title={`Blogging about adventure, backpacking, hiking & more - ${site.siteMetadata.title}`}
    posts={blogPosts.edges}
    showSearch={true}
    site={site}
  />
);

export default BlogIndex;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }

    blogPosts: allDropboxPaperDocumentNew(
      sort: { fields: created_date, order: DESC }
      filter: { fields: { status: { eq: "active" } } }
    ) {
      ...BlogPostFieldsFull
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }
  }
`;
